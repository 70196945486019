import React from "react";
import NewHomesPropertySearchSales from "@templates/new-homes-search-results-template";
import Layout from "@components/layout";
import {
    parseSearchUrl,
    savedSearchParams,
    propertyH1
} from "@components/common/site/utils";

const SearchResult = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    const searchParams = parseSearchUrl("sales", fullpath);

    return (
               <NewHomesPropertySearchSales locationname="/new-homes/for-sale/" pcategorytype="newhomes" ptype="sales" ptypetag="" pstatustype="Sale" fullpathone={fullpathone} fullpathname={fullpath}/>
    );
};

export default SearchResult;
